<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('USER')}) | capitalize}}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre class="text-light" v-if="debug">{{form}}</pre>
        <b-form @submit.prevent="save" inline>
          <!-- username input -->
          <b-form-group
              class="w-50 d-inline-block"
              id="username-form-group"
              :label="$t('USER_USERNAME_LABEL') | capitalize"
              label-for="username-input"
              :state="state('username')"
          >
            <b-form-input
                class="w-100 mr-1"
                id="username-input"
                :value="form.username"
                @change="set('username', $event)"
                :state="state('username')"></b-form-input>

            <b-form-invalid-feedback id="username-input-feedback">
              <div v-for="error in errors('username')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_USERNAME_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <!-- email input -->
          <b-form-group
              class="w-50 d-inline-block"
              id="email-form-group"
              :label="$t('USER_EMAIL_LABEL') | capitalize"
              label-for="email-input"
              :state="state('email')"
          >
            <b-form-input id="email-input"
                          class="w-100 ml-1"
                          :value="form.email"
                          @change="set('email', $event)"
                          :state="state('email')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="email-input-feedback">
              <div v-for="error in errors('email')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_EMAIL_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="w-50 d-inline-block"
              id="name-form-group"
              :label="$t('USER_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')"
          >
            <b-form-input class="w-100 mr-1" id="name-input" v-model="$v.form.name.$model" :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_NAME_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              id="lastName-form-group"
              class="w-50 d-inline-block"
              :label="$t('USER_LAST_NAME_LABEL') | capitalize"
              label-for="lastName-input"
              :state="state('lastName')"
          >
            <b-form-input class="w-100 ml-1" id="lastName-input" v-model="$v.form.lastName.$model"
                          :state="state('lastName')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="lastName-input-feedback">
              <div v-for="error in errors('lastName')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_LAST_NAME_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>


          <b-form-group
              id="password-form-group"
              class="w-50 d-inline-block"
              :label="$t('USER_PASSWORD_LABEL') | capitalize"
              label-for="password-input"
              :state="state('password')"
          >
            <b-form-input class="w-100 mr-1" id="password-input" type="password" v-model="$v.form.password.$model"
                          :state="state('password')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="password-input-feedback">
              <div v-for="error in errors('password')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_PASSWORD_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              id="verification-form-group"
              class="w-50 d-inline-block"
              :label="$t('USER_PASSWORD_VERIFICATION_LABEL') | capitalize"
              label-for="verification-input"
              :state="state('verification')"
          >
            <b-form-input class="w-100 ml-1" id="verification-input" type="password"
                          v-model="$v.form.verification.$model"
                          :state="state('verification')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="verification-input-feedback">
              <div v-for="error in errors('verification')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field:$t('USER_PASSWORD_LABEL'), field2: $t('USER_PASSWORD_VERIFICATION_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              id="enabled-form-group"
              class="w-50 d-inline-block"
              :label="$t('USER_ENABLED_LABEL') | capitalize"
              label-for="enabled-input"
              :state="state('enabled')">
            <b-checkbox v-model="$v.form.enabled.$model" class="w-100 mr-1" id="enabled-input"></b-checkbox>
          </b-form-group>

          <b-form-group
              id="gender-form-group"
              class="w-50 d-inline-block"
              :label="$t('USER_GENDER_LABEL') | capitalize"
              label-for="gender-input"
              :state="state('gender')">
            <b-select :state="state('gender')" v-model="$v.form.gender.$model" class="w-100 ml-1" id="gender-input">
              <b-select-option :value="undefined">{{$t('SELECT_OPTION_LABEL')}}</b-select-option>
              <b-select-option value="M">{{$t('FORMS_GENDER_MALE_LABEL')}}</b-select-option>
              <b-select-option value="F">{{$t('FORMS_GENDER_FEMALE_LABEL')}}</b-select-option>
            </b-select>

            <b-form-invalid-feedback id="gender-input-feedback">
              <div v-for="error in errors('gender')" :key="error.error">
                {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('USER_GENDER_LABEL')},}) | capitalize}}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <single-file-upload @change="setAvatar" selection-mode="single"
                              :label="$t('USER_AVATAR_IMG_LABEL') | capitalize"
                              max-m-b="50"
                              :current-file="form.avatar"
                              :valid-extensions="['png', 'jpg', 'jpeg']"
                              class="w-100 my-2"
                              name="user_img"
                              :id="id"
                              :image="true"></single-file-upload>

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL') | capitalize}}</span>
            <span class="text-capitalize" v-else>{{ $t('FORMS_SAVE_LABEL') | capitalize}}</span>
          </b-button>

        </b-form>
      </b-card-text>
    </b-card>

    <profile-selector :loading="loadingUserProfiles" v-model="form.profiles" :initial-values="profiles" class="my-2"></profile-selector>

    <permission-selector :loading="loadingUserPermissions" v-model="form.permissions" :initial-values="permissions" class="mb-2"></permission-selector>

    <organization-selector :loading="loadingUserOrganizations" v-model="form.organizations" :initial-values="organizations" class="mb-2"></organization-selector>
  </div>
</template>

<script>
import {email, maxLength, minLength, required, requiredIf, sameAs} from "vuelidate/lib/validators";
import ProfileSelector from "@/users/profiles/ProfileSelector";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Users from "@/users";
import Profiles from "@/users/profiles";
import Permissions from "@/users/permissions";
import Constants from "@/constants";
import SingleFileUpload from "@/components/SingleFileUpload";
import PermissionSelector from "@/users/permissions/PermissionSelector";
import OrganizationSelector from "@/organization/OrganizationSelector";
import Organization from "@/organization";

export default {
  name: "UserForm",
  components: {SingleFileUpload, PermissionSelector, ProfileSelector, OrganizationSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    setAvatar(data) {
      this.$v.form.avatar.$model = data[0];
    },
    getData() {
      this.loadingFormData = true;
      Users.findById(this.id)
          .then(resp => {
            resp.data.password = '';
            resp.data.verification = '';
            this.originals.username = resp.data.username;
            this.originals.email = resp.data.email;
            this.form.avatar = resp.data.avatar;
            this.form.username = resp.data.username;
            this.form.name = resp.data.name;
            this.form.lastName = resp.data.lastName;
            this.form.email = resp.data.email;
            this.form.enabled = resp.data.enabled;
            this.form.gender = resp.data.gender;
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('ERROR_LOADING_USER_DATA', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });

      this.loadingUserProfiles = true;
      Profiles.findByUserId(this.id)
          .then(resp => {
            this.profiles = resp.data._embedded.profiles;
            this.form.profiles = this.profiles.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('ERROR_LOADING_USER_PROFILES', e);
          })
          .finally(() => {
            this.loadingUserProfiles = false;
          });

      this.loadingUserPermissions = true;
      Permissions.findByUserId(this.id)
          .then(resp => {
            this.permissions = resp.data._embedded.permissions;
            this.form.permissions = this.permissions.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('ERROR_LOADING_USER_PERMISSIONS', e);
          })
          .finally(() => {
            this.loadingUserPermissions = false;
          });

      this.loadingUserOrganizations = true;
      Organization.findByUserId(this.id)
          .then(resp => {
            this.organizations = resp.data._embedded.organizations;
            this.form.organizations = this.organizations.map(x => x._links.self.href);
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('ERROR_LOADING_USER_ORGANIZATIONS', e);
          })
          .finally(() => {
            this.loadingUserOrganizations = false;
          });
    }
  },
  data() {
    return {
      id: null,
      loadingFormData: false,
      loadingUserProfiles: false,
      loadingUserPermissions: false,
      loadingUserOrganizations: false,
      profiles: [],
      permissions: [],
      organizations: [],
      originals: {
        username: undefined,
        email: undefined
      },
      form: {
        avatar: '',
        enabled: true,
        username: '',
        password: '',
        verification: '',
        name: '',
        lastName: '',
        randomDate: '',
        email: '',
        gender: undefined,
        permissions: [],
        profiles: [],
      },
      editPage: 'USER_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      avatar: {},
      enabled: {
        required
      },
      gender: {
        required
      },
      username: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(32),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.username && this.originals.username === value) return true;

          // simulate async call, fail for all logins with even length
          return Users.usernameExists(value);
        }
      },
      password: {
        required: requiredIf(function () {
          return !this.id;
        }), minLength: minLength(4), maxLength: maxLength(32)
      },
      verification: {
        required: requiredIf(function () {
          return !this.id;
        }), minLength: minLength(4), maxLength: maxLength(32), sameAs: sameAs('password')
      },
      name: {required, minLength: minLength(4), maxLength: maxLength(32)},
      lastName: {required, minLength: minLength(4), maxLength: maxLength(32)},
      email: {
        required,
        email,
        minLength: minLength(4),
        maxLength: maxLength(200),
        isUnique(value) {
          // standalone validator ideally should not assume a field is required
          if (!value || value === '') return true

          if (this.originals.email && this.originals.email === value) return true;

          // simulate async call, fail for all logins with even length
          return Users.emailExists(value);
        }
      },
    }
  },
  computed: {
    controller() {
      return Users;
    }
  }
}
</script>

<style scoped>

</style>
