<template>
  <div>
    <b-form-group :label="label" :description="description">
      <div>
        <b-img-lazy class="d-block mb-2" width="200" v-if="image && this.files.length > 0" :src="imgSrc"></b-img-lazy>
        <b-button @click="openFileDialog" variant="info">
          <b-icon-circle-fill v-if="uploading" animation="throb"></b-icon-circle-fill>
          <b-icon-upload v-else></b-icon-upload>
          {{ $t('FORMS_FILE_UPLOAD_LABEL') }}
        </b-button>
      </div>

    </b-form-group>
    <b-modal :title="$t('FILE_MODAL_TITLE')" id="file-upload-modal" size="xl" hide-footer>
      <files-resume :selection="selectionMode"
                    :id="id"
                    :label="label"
                    :validExtensions="validExtensions"
                    :maxMB="maxMB"
                    :name="name"
                    :description="description"
                    @change="select"></files-resume>
    </b-modal>
  </div>
</template>

<script>
import {SendsMessages} from "@/mixins";
import FilesResume from "@/files/FilesResume";
import Files from "@/files";

export default {
  name: "SingleFileUpload",
  components: {FilesResume},
  mixins: [SendsMessages],
  props: ['name', 'image', 'id', 'label', 'validExtensions', 'maxMB', 'description', 'selectionMode', 'currentFile', 'currentFiles'],
  mounted() {
    if (this.currentFile) {
      this.files.push(this.currentFile)
    }
    if(this.currentFiles) {
      this.files = this.currentFiles;
    }
  },
  methods: {
    select(values) {
      this.$emit('change', values);
      this.files = values;
      this.closeFileDialog();
    },
    openFileDialog() {
      this.$bvModal.show('file-upload-modal');
    },
    closeFileDialog() {
      this.$bvModal.hide('file-upload-modal');
    },
  },
  data() {
    return {
      uploading: false,
      files: []
    }
  },
  computed: {
    imgSrc() {
      return Files.getPathToImg(this.id, this.files[0]);
    }
  }
}
</script>

<style scoped>

</style>
