<template>
  <div>
    <div class="pb-3">
      <b-button id="register-button" v-has-permission="'PROFILE_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        {{ $t('LIST_ADD_LABEL', {catalog: $t('PROFILE')}) | capitalize}}
      </b-button>

      <b-button class="ml-1" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') | capitalize}}</span>
      </b-button>

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize}}</span>
      </b-button>
      <!-- clear button -->
    </div>

    <div class="mb-1">
      <!-- List page filter -->
      <profile-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter" v-model="filter"></profile-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <b-table
        id="profiles-table"
        ref="profilesTable"
        @row-selected="handleSelection"
        v-model="items"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        :filter="filter"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PROFILES')})  | capitalize}}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <template #head(name)="">
        <span>{{ $t('PROFILE_NAME_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(name)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.name }}</span>
        </div>
      </template>

      <template #head(enabled)="">
        <span>{{ $t('LIST_ENABLED_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize}}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right>
            <b-dropdown-item v-has-permission="'PROFILE_UPDATE_PERMISSION'" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.name)">{{ $t('LIST_DELETE_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.name)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize}}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'PROFILE_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.name)" v-else>{{ $t('LIST_ENABLE_LABEL')  | capitalize}}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>

    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="profiles-table"
          ></b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Table} from "@/mixins";
import Profiles from "@/users/profiles";
import eventBus from "@/events";
import Constants from "@/constants";
import ProfileListFilter from "@/users/profiles/ProfileListFilter";
import PageSize from "@/components/PageSize";

export default {
  name: "ProfileList",
  components: {PageSize, ProfileListFilter},
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  mixins: [Table],
  mounted() {
    eventBus.$on(Constants.EVENT_ON_PROFILE_LIST, (totalElements) => {
      this.totalRows = totalElements;
      if (this.currentlySelected) {
        this.originals = this.currentlySelected;
      }
    });
  },
  methods: {},
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'name', sortable: true, tdClass: 'nameTd'},
        {key: 'enabled', sortable: true, tdClass: 'customTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.profilesTable;
    },
    controller() {
      return Profiles;
    }
  },
  data() {
    return {
      registerPage: 'PROFILE_REGISTER_TITLE',
      editPage: 'PROFILE_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  }
}
</script>

<style>
.selectTd {
  width: 15px !important;
}
.nameTd {
  width: 75% !important;
}
</style>
